import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Illustration = styled(GatsbyImage)`
  position: absolute !important;
  top: 62px;
  right: -303px;
  z-index: -1;
  ${media.lg} {
    top: 140px;
    right: -303px;
  }
  ${media.md} {
    display: none !important;
  }
`;

export const Description = styled.p`
  font-size: 40px;
  font-weight: 300;
  color: #00c1de;
  margin: 0 0 79px;
  ${media.sm} {
    font-size: 24px;
    line-height: 1.33;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 40px;
  margin-bottom: 7px;
  ${media.sm} {
    font-size: 32px;
    font-weight: normal;
    line-height: 1.25;
    margin-bottom: 17px;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
`;

export const ContentRight = styled.div`
  padding-top: 118px;
  padding-left: 45px;
  ${media.md} {
    grid-row-start: 1;
    padding-top: 81px;
    padding-left: 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 500px;
  ${media.lg} {
    grid-template-columns: 1fr 290px;
  }
  ${media.md} {
    grid-template-columns: 12fr;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 100px;
  padding-top: 108px;
  ${media.lg} {
    padding-top: 0;
  }
`;
