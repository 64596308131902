import styled from 'styled-components';

import { fontSize, media } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const TriangleSvg1 = styled.img`
  position: absolute;
  top: 0;
  right: -148px;
  ${media.lg} {
    right: -448px;
  }
  ${media.md} {
    right: -498px;
  }
  ${media.sm} {
    top: 0;
    right: -543px;
    height: 500px;
  }
  ${media.xs} {
    top: 0;
    right: -490px;
  }
`;

export const TriangleSvg2 = styled.img`
  position: absolute;
  top: -32px;
  right: -259px;
  ${media.sm} {
    top: 0;
    right: -223px;
  }
  ${media.xs} {
    top: 3px;
    right: -190px;
    height: 500px;
  }
`;

export const TriangleWrapper = styled.div`
  z-index: -1;
`;

export const StyledIllustration = styled.img`
  position: absolute;
  top: -101px;
  right: -183px;
  ${media.lg} {
    top: -80px;
    right: -124px;
    width: 68%;
  }
  ${media.md} {
    position: relative;
    display: block;
    top: 0;
    right: auto;
    height: auto;
    width: 100%;
    margin-top: 59px;
    margin-left: auto;
    margin-right: auto;
  }
  ${media.sm} {
    width: 100%;
    margin-top: 8px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  ${media.lg} {
    padding-right: 0;
  }
  ${media.md} {
    flex-direction: column;
  }
`;

export const BottomText = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 1px;
  color: #6b7696;
  text-transform: uppercase;
  ${media.md} {
    margin-top: 40px;
  }
  ${media.sm} {
    font-size: 10px;
    letter-spacing: 0.83px;
    text-align: center;
    padding-top: 10px;
  }
`;

export const StyledButton = styled(Button)`
  margin-bottom: 201px;
  ${media.md} {
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 32px;
  font-weight: normal;
  ${media.sm} {
    font-size: 38px;
    line-height: 1.26;
    margin-bottom: 40px;
  }
`;

export const LabelText = styled.span`
  font-size: ${fontSize.sm};
  font-weight: 500;
  line-height: normal;
  color: #757395;
  ${media.sm} {
    font-size: 12px;
  }
`;

export const StyledLabelIcon = styled.img`
  flex-shrink: 0;
  margin-right: 20px;

  ${media.sm} {
    display: none;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  ${media.md} {
    margin-bottom: 12px;
  }
`;

export const Content = styled.div`
  ${media.md} {
    margin-bottom: 30px;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 190px;
  padding-bottom: 142px;
  overflow: hidden;
  ${media.md} {
    padding-top: 114px;
    padding-bottom: 71px;
  }
`;
