import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -543px;
  right: -485px;
  z-index: -1;
  ${media.md} {
    top: -432px;
  }
  ${media.sm} {
    top: -392px;
    right: -479px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 41px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
  strong {
    font-weight: normal;
    color: #00c1de;
  }
`;

export const ContentLeft = styled.div`
  padding-top: 70px;
  ${media.md} {
    padding-top: 0;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  img {
    position: absolute;
    left: 23px;
    width: 65%;
    ${media.md} {
      left: auto;
    }
    ${media.xs} {
      width: 100%;
      max-width: 320px;
    }
  }
  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;
    ${media.md} {
      display: flex;
      justify-content: center;
      width: 440px;
    }
    ${media.sm} {
      height: 185px;
      width: 100%;
    }
  }
  .illustration-card-one {
    top: 18%;
    width: 64.7%;
    left: 24px;
    ${media.md} {
      left: auto;
    }
    ${media.xs} {
      width: 100%;
      max-width: 320px;
    }
  }
  .illustration-card-two {
    top: 47%;
  }
  .illustration-card-three {
    top: 78%;
  }
  .illustration-card-four {
    top: 108%;
  }
`;

export const Grid = styled(SharedGrid)``;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 175px;
  margin-bottom: 192px;
  ${media.sm} {
    padding-bottom: 250px;
    margin-bottom: 32px;
  }
`;
