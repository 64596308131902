import { motion } from 'framer-motion';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import IllustrationCardFourSvgUrl from './images/illustration-card-four.url.svg';
import IllustrationCardOneSvgUrl from './images/illustration-card-one.url.svg';
import IllustrationCardThreeSvgUrl from './images/illustration-card-three.url.svg';
import IllustrationCardTwoSvgUrl from './images/illustration-card-two.url.svg';
import TriangleSvgUrl from './images/solutions-triangle-providers.url.svg';
import {
  Wrapper,
  Inner,
  Grid,
  ContentLeft,
  ContentRight,
  StyledTitle,
  Description,
  BackgroundImageWrapper,
  TriangleImage,
} from './service-providers-language.styles';

export const Language = ({ title, description }) => {
  const [animationContainer, animationContainerView] = useInView({
    threshold: 1,
  });

  const motionEase = [0.25, 0.1, 0.25, 1];

  const variantsContent = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      y: -50,
      transition: {
        delay: custom,
        duration: 0.7,
        ease: motionEase,
      },
    }),
  };

  return (
    <Wrapper>
      <Inner>
        <Grid
          columns={2}
          gap={0}
          media={{
            sm: { columns: 1 },
          }}
        >
          <GridCell>
            <ContentLeft>
              <StyledTitle
                as="h2"
                size="xl"
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(title),
                }}
              />
              <Description
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(description),
                }}
              />
            </ContentLeft>
          </GridCell>
          <GridCell>
            <ContentRight ref={animationContainer}>
              <motion.div
                className="illustration-wrapper"
                initial="hidden"
                animate={animationContainerView && 'visible'}
              >
                <motion.img
                  src={IllustrationCardOneSvgUrl}
                  className="illustration-card-one"
                  variants={variantsContent}
                  custom={0}
                />
                <motion.img
                  src={IllustrationCardTwoSvgUrl}
                  className="illustration-card-two"
                  variants={variantsContent}
                  custom={0.5}
                />
                <motion.img
                  src={IllustrationCardThreeSvgUrl}
                  className="illustration-card-three"
                  variants={variantsContent}
                  custom={1}
                />
                <motion.img
                  src={IllustrationCardFourSvgUrl}
                  className="illustration-card-four"
                  variants={variantsContent}
                  custom={1.5}
                />
              </motion.div>
            </ContentRight>
          </GridCell>
        </Grid>
      </Inner>
      <BackgroundImageWrapper>
        <TriangleImage src={TriangleSvgUrl} />
      </BackgroundImageWrapper>
    </Wrapper>
  );
};
