import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -501px;
  right: -55px;
  ${media.md} {
    top: -142px;
    right: -479px;
  }
  ${media.sm} {
    top: -439px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 33px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
  strong {
    font-weight: normal;
    color: #00c1de;
  }
`;

export const ContentLeft = styled.div`
  padding-top: 133px;
  ${media.sm} {
    padding-top: 0;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.xs} {
    display: flex;
    justify-content: center;
  }
  img {
    position: absolute;
  }
  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;
    ${media.md} {
      display: flex;
      justify-content: center;
      height: 270px;
      width: 440px;
    }
    ${media.sm} {
      height: 315px;
      width: 100%;
    }
    ${media.xs} {
      height: 183px;
      max-width: 320px;
    }
  }
  .illustration-card-one {
    top: 35%;
    width: 77.7%;
    left: 2.2%;
    ${media.sm} {
      top: 14%;
      left: auto;
    }
  }
  .illustration-card-two {
    top: 66%;
    width: 72%;
    left: 4.8%;
    ${media.sm} {
      top: 45%;
      left: auto;
    }
  }
  .illustration-card-three {
    top: 80%;
    width: 61%;
    left: 10%;
    ${media.sm} {
      top: 58%;
      left: auto;
    }
  }
  .illustration-card-logo {
    top: 54%;
    left: 31.5%;
    width: 20%;
    ${media.md} {
      top: 47%;
    }
    ${media.sm} {
      top: 33%;
      left: auto;
    }
    ${media.xs} {
      top: 16%;
    }
  }
`;

export const Grid = styled(SharedGrid)``;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 147px;
  margin-bottom: 30px;
  ${media.sm} {
    padding-bottom: 150px;
  }
  ${media.xs} {
    padding-bottom: 70px;
  }
`;
