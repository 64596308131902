import * as React from 'react';

import CapabilitieSvgUrl1 from '../icons/benefits.url.svg';
import CapabilitieSvgUrl3 from '../icons/cashback.url.svg';
import CapabilitieSvgUrl2 from '../icons/rewards.url.svg';
import CapabilitieSvgUrl4 from '../icons/survey.url.svg';

import {
  Wrapper,
  Inner,
  Grid,
  GridItem,
  ItemWrapper,
  ItemImg,
  ImageStatic,
  ItemContent,
  ItemTitle,
  ItemDescription,
} from './service-providers-intro-capabilities-block.styles';

export const CapabilitiesBlock = ({ className, items }) => (
  <Wrapper className={className}>
    <Inner>
      <Grid
        columns={4}
        gap={0}
        media={{
          md: { columns: 1 },
        }}
      >
        <GridItem borderRight>
          <ItemWrapper>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl1} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[0].title}</ItemTitle>
              <ItemDescription>{items[0].description}</ItemDescription>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem borderRight>
          <ItemWrapper>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl2} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[1].title}</ItemTitle>
              <ItemDescription>{items[1].description}</ItemDescription>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem borderRight>
          <ItemWrapper>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl3} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[2].title}</ItemTitle>
              <ItemDescription>{items[2].description}</ItemDescription>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem>
          <ItemWrapper>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl4} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[3].title}</ItemTitle>
              <ItemDescription>{items[3].description}</ItemDescription>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
      </Grid>
    </Inner>
  </Wrapper>
);
