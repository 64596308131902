import * as React from 'react';

import { GridCell } from 'components/shared/grid';

import ItemIconSvgUrl1 from '../images/api-features.url.svg';
import ItemIconSvgUrl5 from '../images/campaign.url.svg';
import ItemIconSvgUrl4 from '../images/email.url.svg';
import ItemIconSvgUrl2 from '../images/reporting.url.svg';
import ItemIconSvgUrl3 from '../images/tools.url.svg';

import {
  Grid,
  ItemWrapper,
  ItemContent,
  ItemImg,
  ImageStatic,
  ItemTitle,
  ItemDescription,
} from './service-providers-features-items.styles';

export const Items = ({ items }) => (
  <Grid
    columns={2}
    gap={0}
    media={{
      md: { gap: 50 },
      sm: { columns: 1, gap: 0 },
    }}
  >
    <GridCell>
      <ItemWrapper>
        <ItemImg>
          <ImageStatic src={ItemIconSvgUrl1} width={70} height={70} alt="" loading="lazy" />
        </ItemImg>
        <ItemContent>
          <ItemTitle
            as="h3"
            dangerouslySetInnerHTML={{
              __html: items[0].title,
            }}
          />
          <ItemDescription>{items[0].description}</ItemDescription>
        </ItemContent>
      </ItemWrapper>
    </GridCell>
    <GridCell>
      <ItemWrapper>
        <ItemImg>
          <ImageStatic src={ItemIconSvgUrl2} width={70} height={70} alt="" loading="lazy" />
        </ItemImg>
        <ItemContent>
          <ItemTitle
            as="h3"
            dangerouslySetInnerHTML={{
              __html: items[1].title,
            }}
          />
          <ItemDescription>{items[1].description}</ItemDescription>
        </ItemContent>
      </ItemWrapper>
    </GridCell>
    <GridCell>
      <ItemWrapper>
        <ItemImg>
          <ImageStatic src={ItemIconSvgUrl3} width={70} height={70} alt="" loading="lazy" />
        </ItemImg>
        <ItemContent>
          <ItemTitle
            as="h3"
            dangerouslySetInnerHTML={{
              __html: items[2].title,
            }}
          />
          <ItemDescription>{items[2].description}</ItemDescription>
        </ItemContent>
      </ItemWrapper>
    </GridCell>
    <GridCell>
      <ItemWrapper>
        <ItemImg>
          <ImageStatic src={ItemIconSvgUrl4} width={70} height={70} alt="" loading="lazy" />
        </ItemImg>
        <ItemContent>
          <ItemTitle
            as="h3"
            dangerouslySetInnerHTML={{
              __html: items[3].title,
            }}
          />
          <ItemDescription>{items[3].description}</ItemDescription>
        </ItemContent>
      </ItemWrapper>
    </GridCell>
    <GridCell>
      <ItemWrapper>
        <ItemImg>
          <ImageStatic src={ItemIconSvgUrl5} width={70} height={70} alt="" loading="lazy" />
        </ItemImg>
        <ItemContent>
          <ItemTitle
            as="h3"
            dangerouslySetInnerHTML={{
              __html: items[4].title,
            }}
          />
          <ItemDescription>{items[4].description}</ItemDescription>
        </ItemContent>
      </ItemWrapper>
    </GridCell>
  </Grid>
);
